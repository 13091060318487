<template>
  <main id="child-category" class="child-category-container">
    <nuxt-lazy-hydrate when-idle>
      <article-section-hero :breadcrumb="breadcrumb" :title="childCategory.title" :description='childCategory.description' :cover="childCategory.cover" />
    </nuxt-lazy-hydrate>

    <nuxt-lazy-hydrate when-visible>
      <lazy-article-section-paginated :paginated-articles="paginatedArticles"
                               :parent-category-slug="parentCategory.slug"
                               :child-category-slug="childCategory.slug"
                               :child-category-title="childCategory.title" />
    </nuxt-lazy-hydrate>

    <nuxt-lazy-hydrate when-visible>
      <lazy-article-section-tool-bar :tools="toolbar" no-space />
    </nuxt-lazy-hydrate>

    <nuxt-lazy-hydrate when-visible>
      <lazy-article-section-discover-categories v-if="childCateogires.length" :categories="childCateogires" :parent-category-slug="parentCategory.slug"  />
    </nuxt-lazy-hydrate>

    <nuxt-lazy-hydrate when-visible>
      <div class="blocs">
        <template v-for="bloc in childCategory.blocs" :key="bloc.id">
            <lazy-article-section-text-bloc :bloc="bloc" />
        </template>
      </div>
    </nuxt-lazy-hydrate>
  </main>
</template>

<script>
export default {
  props: {
    paginatedArticles: {
      type: Object,
      required: true
    },
    parentCategory: {
      type: Object,
    },
    childCategory: {
      type: Object,
    },
    toolbar: {
      type: Object,
      required: true
    },
  },
  computed: {
    breadcrumb() {
      return  [
        {
          label: this.parentCategory.title,
          to: `/${this.parentCategory.slug}`
        },
        {
          label: this.childCategory.title,
          to: `/${this.parentCategory.slug}/${this.childCategory.slug}`
        },
      ]
    },
    childCateogires() {
      return this.parentCategory.child_categories.filter(category => category.slug !== this.childCategory.slug)
    }
  },
  provide() {
    return {
      parentCategory: this.parentCategory,
      childCategory: this.childCategory,
    }
  },
}
</script>

<style lang="less" scoped>
.child-category-container {
  position: relative;
  min-height: 70vh;
}
</style>